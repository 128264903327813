import { Col, Container, Row } from "react-bootstrap";
import "../Hero/Hero.css";
import img1 from "../Images/brooklyn.png";
import img2 from "../Images/2.jpeg";
import img3 from "../Images/3.jpeg";
import img4 from "../Images/4.jpeg";
import img5 from "../Images/5.jpeg";
import img6 from "../Images/6.jpeg";
import img7 from "../Images/7.jpeg";
import img8 from "../Images/8.jpeg";

function Hero() {
  return (
    <>
      <Container className="mt-5 mb-5">
        <Row className="a16">
          <Col sm={12} xxl={6} lg={6} xl={6}>
            <div className="a5">
              <h1 className="a6">
                PIONEERING PARKING <br /> ASSETS ONCHAIN
              </h1>
              {/* <p className="a9">ASSETS ONCHAIN</p> */}
              {/* <div className="a10">
                <img className="a11" src={img7} alt="img7" />
                <img className="a11" src={img8} alt="img7" />
                <img className="a11" src={img6} alt="img7" />
              </div> */}
              <br />

              <div>
                {" "}
                <p className="a9a">
                  Supercharge your returns by investing in parking lots in the
                  heart of New York City
                </p>
                <button className="a12">Get Started</button>
                <br />
                <br />
              </div>
            </div>
          </Col>
          <Col sm={12} xxl={6} lg={6} xl={6}>
          <img className="a8" src={img1} alt="img1" />
            {/* <div className="a7">
              <div className="a13">
                <img className="a8" src={img1} alt="img1" />
                <img className="a8" src={img2} alt="img2" />
              </div>
              <div className="a14">
                <img className="a8" src={img3} alt="img3" />
                <img className="a8" src={img4} alt="img4" />
                <img className="a8" src={img5} alt="img5" />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Hero;
