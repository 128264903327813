import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Faq1/Faq1.css";
import { FiPlus } from "react-icons/fi";
import plusImage from "../Images/plus.svg";  
import crossImage from "../Images/close.svg";

import { useRef, useState, useEffect, useLayoutEffect } from "react";

export default function Faq1() {

  const [name, setName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  // const [icon, setIcon] = useState({a2});

  const data = [
    {
      id: 1001,
      label: "How does this compare to trading in crypto?",
      detail:
        "Trading in crypto can be lucrative for the top traders. However, the biggest limiter to generating wealth to crypto is that such alpha usually does not scale well e.g. size of liquidity on LP's limiting the absolute returns that they can ever make. As such, sustainable yield sources such as DefiDriveway's is useful for wealth accumulation.",
    },
    {
      id: 1002,
      label: "How is tokenizing private commercial real estate good?",
      detail:
        "Typically access to these deals is heavily restricted to having the right network. Also, the red tape surrounding getting involved with these deals can introduce a ton of friction. However, by tokenizing these deals, we are introducing more flexibility into these deals programmatically.",
    },
    {
      id: 1003,
      label: "Why should I park my funds in DefiDriveway?",
      detail:
        "DefiDriveway aims to work on all kinds of real estate deals that bring sustainable great yields on-chain. These deals are catered towards those that have crypto exposure but want to also diversify their yield in the space. Whether it be funds, Protocol Treasuries, or even just individuals that want to split up their investments, DefiDriveway's main draw is the fact that it can provide great sustainable yield on-chain.",
    },
    
  ];

  const handleClick = (id) => {
    setSelectedId(id !== selectedId ? id : null);
    // setIcon(id);
  };

  return (
    <>
      <br />
      <br />
      <Container>
        <Row>
          <Col>
            <div className="g1">
              <div className="g2">
                <div className="g3" id="faq">FAQ</div>
            
                {data.map((val, i) => (
                  <div className="g4"
                    key={i}
                  >
                    <div className="g6"
                      onClick={() => {
                        handleClick(val.id);
                        setActive(!active);
                        setName(val.label);
                      }}
                    >
                      <div className="g5"
                      // className={`${
                      //   selectedId === val.id
                      //     ? styles.activeQuestion
                      //     : styles.inactiveQuestion
                      // } ${styles.cmsFaqQuestion}`}
                      >
                        {val.label}
                      </div>
                      <div>
                        <div className="g7">
                          <img
                           src={selectedId === val.id ? crossImage : plusImage}
                            alt="plusMinus"
                            width="20px"
                          />
                        </div>
                      </div>
                    </div>
                    {selectedId === val.id && (
                      <p className="g8" ref={ref}>
                        {val.detail}
                      </p>

                    )}
                    
                <hr />
                  </div>

                ))}
              </div>
            </div>
            
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
}



