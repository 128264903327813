import "../Header/Header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//import logo from "../Images/footer_logo.svg";
import logo from "../Images/footer_logo.svg";

function Header() {
  return (
    <>
      {/* <div className="a3">
        The Chateau App is now live on Arbitrum One. Learn More
      </div> */}
      <Navbar expand="lg" >
        <Container>
          <Navbar.Brand href="#">
            {/* <img className="logo-image" src={logo} alt="logo" shape-rendering="crispEdges" /> */}
            <div className="titleText">
              DefiDriveway
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" style={{background: "#fff"}} />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll>
              <Nav.Link className="a2" href="#deals">
                Strategy
              </Nav.Link>
              <Nav.Link className="a2" href="#about">
                About
              </Nav.Link>
              <Nav.Link className="a2" href="#works">
                How It Works
              </Nav.Link>
              <Nav.Link className="a2" href="#faq">
                FAQ
              </Nav.Link>
              <Nav.Link className="a2" href="https://defidriveway.gitbook.io/defidriveway-docs/" target="_blank">
                Docs
              </Nav.Link>
            </Nav>
            <Form className="d-flex a4">
              <Button className="a4">Coming soon</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default Header;
