import React from "react";
import a1 from "../Images/a1.svg";
import "../Footer/Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../Images/footer_logo.svg";
import soc from "../Images/soc.jpeg";
import soc1 from "../Images/soc1.jpeg";

export default function Footer() {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="e1 mt-5">
              <div className="e2">
                {/* <img className="e3" src={logo} alt="sdsdf" /> */}
                <div className="footerTitle">DefiDriveway</div>
              </div>
              <div className="e4 mt-3">
              <div className="e5">
                <a href="https://twitter.com/DefiDriveway" target="_blank" rel="noopener noreferrer">
                  <img src={soc1} className="e5" alt="asd" />
                </a>
              </div>
                {/* <div className="e5">
                  <img src={soc} className="e5" alt="asd" />
                </div> */}
              </div>
              <div className="e6 mt-3">
                {/* <div className="e7">contact@defidriveway.nyc</div> */}
                {/* <div className="e7">+1 888 555 2121</div>
                <div className="e7">
                  Hilll Underpass Apt. 243, Boston, Montana, 52297, USA
                </div> */}
              </div>
            </div>
          </Col>
          <Col>
            <div className="e9 mt-5">
              <div className="e10">
                {/* <div className="e11">
                  <div className="e12">Details</div>
                  <div className="e13">About Us</div>
                  <div className="e13">How It Works</div>
                  <div className="e13">FAQ</div>
                </div> */}
                {/* <div className="e11">
                  <div className="e12">Fine Print</div>
                  <div className="e13">Liceinsing</div>
                  <div className="e13">StyleGuide</div>
                  <div className="e13">Changelog</div>
                </div> */}
                
              </div>
            </div>
          </Col>
          <hr className="mt-5" />
          <div className="e14">© 2024 DefiDriveway</div>
        </Row>
      </Container>
    </>
  );
}
