import { Container } from "react-bootstrap";
import "../Section1/Section1.css";

function Section1() {
  return (
    <>
      {/* <Container fluid className="ms-0 me-0"> */}
      <div className="b1">
        <div className="b3">
          <div className="b2">
            <span id="deals">
            STRATEGY
            {/* <br />
            <p className="b3">
            Targeted Equity Return: 18-20%
            <br />
            Est. Time Horizon: 5 years from closing
            <br />
            Number of Properties in Acquisiton Pipeline: 3
            </p> */}
        
            </span>
          </div>
          <div className="b5 mt-2">
            <p className="b4">
            Focus on existing commercial real estate in the Greater New York City Metro Area with the potential for substantial in-place cash flow
            </p>
          </div>
          <div className="b6 mt-3">
            <div className="b7">
              <div className="b8">18 - 20%</div>
              <p>Target Equity Return</p>
            </div>{" "}
            <div className="b7">
              <div className="b8">5 years</div>
              <p>Est Time Horizon</p>
            </div>{" "}
            <div className="b7">
              <div className="b8">$70M</div>
              <p>Acquisiton Pipeline Size</p>
            </div>
          </div>
          {/* <div className="b5 mt-2">
            <p className="b4a">
              We are now offering reservations for non-U.S investors. To access
              the full investor deck, please fill out the intake form below.
            </p>
          </div> */}
          <div className="b9 mt-2">
          <a href="https://pitch.com/v/ai-defidriveway-fundraising-pitch-x99gfq/f0fefcba-53a2-41bd-ad41-030f6b96e79f" target="_blank">
              <button className="b10">Learn More</button>
          </a>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </>
  );
}
export default Section1;
