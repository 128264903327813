import "./App.css";
import Footer from "./Components/Footer/Footer";
import Section3 from "./Components/Section3/Section3";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Section1 from "./Components/Section1/Section1";
import Section2 from "./Components/Section2/Section2";
import Faq from "./Components/FAQ/Faq";
import Faq1 from "./Components/Faq1/Faq1";

function Dashboard() {
  return (
    <div>
      <Header />
      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      {/* <Faq /> */}
      <Faq1 />
      <hr />
      <Footer />
    </div>
  )
}

export default Dashboard;