import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import a1 from "../Images/9.svg";
import "../Section3/Section3.css";

export default function Section3() {
  return (
    <>
      <Container className="mt-4">
        <div className="c2">
          <div className="c3">
            <div className="c1" id="works">How it Works</div>
            <Row className="mt-5">
              <Col>
                <div className="d2">
                  <div className="d4a">
                    <div className="d4 mb-4">
                      <div className="d6">
                        <div className="d7">Great Deals</div>
                        <div className="d8">
                        We look for real estate assets where the stabilized yield will be high, and where there is a significant chance of capital appreciation
                        </div>
                      </div>
                    </div>
                    <div className="d4 mb-4">
                      <div className="d6">
                        <div className="d7">Operating Partners</div>
                        <div className="d8">
                        We work with experienced brokers to lease our assets to top tier management companies with 1+B Market caps
                        </div>
                      </div>
                    </div>{" "}
                    <div className="d4 mb-4">
                      <div className="d6">
                        <div className="d7">Tokenization</div>
                        <div className="d8">
                        We tokenize the asset on-chain so that yield distribution and asset transferrals are simple as can be!
                        </div>
                      </div>
                    </div>{" "}
                    <div className="d4 mb-4">
                      <div className="d6">
                        <div className="d7">PE made accessible </div>
                        <div className="d8">
                        You get exposure to a great asset in a fantastic asset class that has historically outperformed the S&P500 - with just one click
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <img src={a1} alt="sfd" className="d1" /> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}
