import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Chateau from "./Components/Chateau/Chateau";
import Dashboard from "./Dashboard";

function Apps() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="*" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/dashboard" element={<Chateau />} /> */}
      </Routes>
      </BrowserRouter>
    </>
  )
}

export default Apps;
