import { Col, Container, Row } from "react-bootstrap";
import "../Section2/Section2.css";
import a1 from "../Images/Futuristic-4.svg";
import a2 from "../Images/a2.svg";
import a3 from "../Images/a3.svg";
import a4 from "../Images/a4.svg";
import a5 from "../Images/a5.svg";
import a6 from "../Images/a6.svg";

function Section2() {
  return (
    <>
      <Container className="mt-4">
        <div className="c2">
          <div className="c3">

            <Row className="mt-5">
              <Col>
                <div className="c11">
                  <div className="c10">
                    <div className="c5">
                      <div className="c6">
                        <img className="c6a" src={a1} alt="asd" />
                      </div>
                      <div className="c7">
                      <div className="c8" id="about">SOURCING THE BEST REAL ASSETS</div>
                      <div className="c9">
                        The DefiDriveway team is composed of a mix of experienced financiers and passionate Web3 developers working tirelessly to bring Real World Assets on-chain.
                      </div>
                      <div className="c9">
                        We leverage our network and expertise to grant international investors access to the best real estate deals in the US.
                      </div>

                      <div className="c8">WHY COMMERCIAL REAL ESTATE?</div>
                      <div className="c9">
                        High demand. Limited supply. And lack of access. Commercial real estate in NYC has historically performed better than traditional portfolios of large cap stocks and bonds.
                      </div>
                      <div className="c9">
                        But investors have been barred from investing in them due to the difficulty of sourcing whole deals and large capital requirements.
                      </div>
                      <div className="c9">
                        That's why we're here to make your life easy.
                      </div>

                    </div>
                    </div>
                    
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
export default Section2;
